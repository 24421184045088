const debug = require('debug')('mph:app:ui-extension');

const BEST_IMAGE_OVAL_BORDER_SIZE_DEFAULT = 5;
const BEST_IMAGE_OVAL_BORDER_COLOR_DEFAULT = '#41B16E';

let websdkHighUIExt; // loaded on demand
let websdkPassiveVideoUIExt; // loaded on demand
let livenessHighUpdateWarningConsoleLog = false; // display warning to the user only once if deprecated update method is used by the integrator
let livenessHighResetWarningConsoleLog = false; // display warning to the user only once if deprecated reset method is used by the integrator

async function loadHighUiExtension() {
    if (!websdkHighUIExt) { websdkHighUIExt = await import('././websdk-ui-components/websdk-high-ui-extension.js'); }
}
async function loadPassiveVideoUiExtension() {
    if (!websdkPassiveVideoUIExt) { websdkPassiveVideoUIExt = await import('././websdk-ui-components/websdk-passive-video-ui-extension.js'); }
}

// ******** High UI extension - APIs ********
// deprecated to use 'livenessHigh' name, present for backward compatibility
module.exports.updateLivenessHighGraphics = async (videoElementId, trackingData) => {
    if (!livenessHighUpdateWarningConsoleLog) {
        console.warn('Deprecated function updateLivenessHighGraphics called, please use updateLivenessActiveGraphics method instead !');
        livenessHighUpdateWarningConsoleLog = true;
    }
    loadHighUiExtension().then(() => {
        websdkHighUIExt.updateLivenessActiveGraphics(videoElementId, trackingData);
    });
};

// deprecated to use 'livenessHigh' name, present for backward compatibility
module.exports.resetLivenessHighGraphics = (graphicOptions) => {
    if (!livenessHighResetWarningConsoleLog) {
        console.warn('Deprecated function resetLivenessHighGraphics called, please use resetLivenessActiveGraphics method instead !');
        livenessHighResetWarningConsoleLog = true;
    }
    loadHighUiExtension().then(() => {
        websdkHighUIExt.resetLivenessActiveGraphics(graphicOptions);
    });
};

module.exports.updateLivenessActiveGraphics = async (videoElementId, trackingData) => {
    loadHighUiExtension().then(() => {
        websdkHighUIExt.updateLivenessActiveGraphics(videoElementId, trackingData);
    });
};

module.exports.resetLivenessActiveGraphics = (graphicOptions) => {
    loadHighUiExtension().then(() => {
        websdkHighUIExt.resetLivenessActiveGraphics(graphicOptions);
    });
};

// ******** Passive video UI extension - APIs ********
module.exports.initPassiveVideoGraphics = (videoElementId, graphicOptions) => {
    loadPassiveVideoUiExtension().then(() => {
        websdkPassiveVideoUIExt.initPassiveVideoGraphics(videoElementId, graphicOptions);
    });
};

module.exports.displayPassiveVideoAnimation = (trackingInfo) => {
    loadPassiveVideoUiExtension().then(() => {
        websdkPassiveVideoUIExt.displayPassiveVideoAnimation(trackingInfo);
    });
};
module.exports.stopPassiveVideoAnimation = () => {
    loadPassiveVideoUiExtension().then(() => {
        websdkPassiveVideoUIExt.stopPassiveVideoAnimation();
    });
};
module.exports.displayPassiveVideoBestImage = (bestImage, bestImageInfo, bestImageWrapper, graphicOptions) => {
    loadPassiveVideoUiExtension().then(() => {
        module.exports.displayPassiveVideoBestImage(bestImage, bestImageInfo, bestImageWrapper, graphicOptions);
    });
};
module.exports.displayBestImage = (bestImage, bestImageInfo, bestImageWrapper) => {
    loadPassiveVideoUiExtension().then(() => {
        websdkPassiveVideoUIExt.displayBestImage(bestImage, bestImageInfo, bestImageWrapper);
    });
};
module.exports.resetBestImage = () => {
    loadPassiveVideoUiExtension().then(() => {
        websdkPassiveVideoUIExt.resetBestImage();
    });
};

/**
 * @api {Javascript} displayPassiveVideoBestImage : Display best image in an oval box (as passive liveness workflow) and center the face inside the box
 * @param {Blob} bestImage best image blob as retrieved by server
 * @param {object} bestImageInfo
 * @param {HTMLDivElement} bestImageWrapper
 * @param {object} graphicOptions contains custom css options
 * @param {Object} [graphicOptions.oval] options to customize oval graphics
 * @param {number} [graphicOptions.oval.borderSize] css size for oval border
 * @param {string} [graphicOptions.oval.borderColor] css color for oval border
 */
module.exports.displayPassiveVideoBestImage = (bestImage, bestImageInfo, bestImageWrapper, graphicOptions = {}) => {
    module.exports.displayAndCenterBestImage(bestImage, bestImageInfo, bestImageWrapper, graphicOptions);
};

// ******** Common UI extension - APIs ********
/**
 * @api {Javascript} displayAndCenterBestImage : Display best image in an oval box and center the face inside the box
 * @param {Blob} bestImage best image blob as retrieved by server
 * @param {object} bestImageInfo
 * @param {HTMLDivElement} bestImageWrapper
 * @param {object} graphicOptions contains custom css options
 * @param {Object} [graphicOptions.oval] options to customize oval graphics
 * @param {number} [graphicOptions.oval.borderSize] css size for oval border
 * @param {string} [graphicOptions.oval.borderColor] css color for oval border
 */
module.exports.displayAndCenterBestImage = (bestImage, bestImageInfo, bestImageWrapper, graphicOptions = {}) => {
    debug('UI Extension : Display best image in a box', { bestImageInfo }, { graphicOptions });
    if (!bestImage || !bestImageWrapper) {
        const errorMsg = 'Some mandatory parameters are missing';
        debug('Error: ' + errorMsg);
        throw new Error(errorMsg);
    }
    // WARNING : https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Operators/Optional_chaining
    // Optional chaining (?.) is not supported by all the navigator such as Safari 13.4 less
    const bestImageOvalBorderSize = (graphicOptions.oval && graphicOptions.oval.borderSize) ? graphicOptions.oval.borderSize : BEST_IMAGE_OVAL_BORDER_SIZE_DEFAULT;
    const bestImageOvalBorderColor = (graphicOptions.oval && graphicOptions.oval.borderColor) ? graphicOptions.oval.borderColor : BEST_IMAGE_OVAL_BORDER_COLOR_DEFAULT;

    const bestImageURL = window.URL.createObjectURL(bestImage);
    // get w&h of the provided image because the provided h & w are not always the same as the provided image size
    const image = new Image();
    image.src = bestImageURL;
    image.onload = function () {
        const bestImgSelector = bestImageWrapper.startsWith('#') || bestImageWrapper.startsWith('.') ? bestImageWrapper : '#' + bestImageWrapper;
        document.querySelectorAll(bestImgSelector)
            .forEach((bestImgElement) => {
                bestImgElement.style.backgroundImage = `url(${bestImageURL})`;
                bestImgElement.style.backgroundRepeat = 'no-repeat';
                bestImgElement.style.width = 'calc(40vh/1.3)';
                bestImgElement.style.height = '40vh';
                bestImgElement.style.position = 'relative';
                bestImgElement.style.borderRadius = '50%';
                bestImgElement.style.backgroundSize = 'cover';
                bestImgElement.style.backgroundPosition = 'center';
                bestImgElement.style.border = 'solid ' + bestImageOvalBorderSize + 'px';
                bestImgElement.style.borderColor = bestImageOvalBorderColor;

                if (bestImageInfo) {
                    const { boxX, boxY, boxW, boxH } = bestImageInfo;
                    const sizeCoeff = bestImgElement.offsetWidth / boxW;
                    bestImgElement.style.backgroundSize = (image.width * sizeCoeff).toFixed() + 'px';
                    // adjust Y position to fit oval shape ( oval form ==>  height = width x 1.3)
                    // as height is 30% bigger so we center face on Y axis by adding the half of the 30% of surface that exceeds
                    bestImgElement.style.backgroundPositionY = -((boxY - 0.3 * boxH / 2) * sizeCoeff).toFixed() + 'px';
                    bestImgElement.style.backgroundPositionX = -(boxX * sizeCoeff).toFixed() + 'px';
                    debug('Best image centered (backgroundSize=' + bestImgElement.style.backgroundSize + ', backgroundPositionY=' + bestImgElement.style.backgroundPositionY +
                        ' backgroundPositionX=' + bestImgElement.style.backgroundPositionX + ')');
                } else {
                    debug('Warning: No best image information found to center the face');
                }
            });
        window.URL.revokeObjectURL(bestImageURL);
    };
};
